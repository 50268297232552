import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Button } from '@mui/material'

import Image1 from '../images/drugsAreBadMmkay/ttw1.jpg'
import Image2 from '../images/drugsAreBadMmkay/ttw3.jpg'
import Image3 from '../images/drugsAreBadMmkay/gtavtc.png'
import Image4 from '../images/drugsAreBadMmkay/acnhgd.png'
import Image5 from '../images/drugsAreBadMmkay/ttw2.jpg'

import './carouselStyles.css'

function CarouselComponent(props)
{
  var bg =require('../images/drugsAreBadMmkay/dabm.jpg')
    var items = [
        {
            name: "Tiny Tina's Wonderlands",
            description: "Let's let the good times roll! Literally with all the dice Tina leaves lying around! Lets be the Fatemakers we were BORN to be and stay as loaded as our guns are!",
            date: "Tuesday, April 5th",
            image: Image1
        },
        {
            name: "Tiny Tina's Wonderlands",
            description: "The game so nice, we're playing it twice!! Aw yeah, let's level our guys up and make it so Tina Tiny will NOT take us out and fuck us on the street...or anywhere for that matter.",
            date: "Tuesday, April 12th",
            image: Image2
        },
        {
          name: "GTA The Contract",
          description: "Dr. Dre said... TO GET BACK THAT LEAKED MUSIC! Let's wrap this up for our most favorite VIP <3 Let's make sure that idiot that stole his music is more WASTED than we are!! ...if that's even possible! And if we have any extra time, lets sell some SonxOfXtinarchy chronic!",
          date: "Tuesday, April 19th",
          image: Image3
        },
        {
            name: "ACNH Grange Displays",
            description: "Its fooour twwwenty - UUNNHH! What a better way to celebrate than another Grange Display event based on our month theme (Drugs are bad, mmkay)! With the absolute wild success of the first event, I know the displays this month will really take us on a TRIP!",
            date: "Wednesday, April 20th",
            image: Image4
        },
        {
            name: "Tiny Tina's Wonderlands",
            description: "No you are not reading this wrong. Tiny Tina, Round 3! Why fix what ain't broken? If we all love this one, lets EMBRACE It! Time to get as smashed as our melee weapons and fuck shit up! ",
            date: "Tuesday, April 26th",
            image: Image5
        },
    ]

    return (
        <Carousel>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
        </Carousel>
    )
}

function Item(props)
{
  var bgImg =props.item.image
  var sectionStyle = {
  width: "100%",
  height: "50vh",
  backgroundSize: "100% 100%",
  backgroundImage: "url("+ bgImg +")"
};
    return (
      <div style={sectionStyle} id="caroContainer">
        <div class="caroTextBackground caroTextCenter">
              <h2 id="headerText">{props.item.name}</h2>
        </div>
        <div class="caroContent caroTextBackground" id="contentText">
              <div id="contentDate">{props.item.date}</div>
              <div>{props.item.description}</div>
        </div>
      </div>
    )
}
export default CarouselComponent;
