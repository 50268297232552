import CarouselComponent from './carousel/CarouselComponent'
import ContentComponent from './content/ContentComponent'
import './App.css';

function App() {
  return (
    <div className="App">
    <CarouselComponent />
    <ContentComponent />
    </div>
  );
}

export default App;
