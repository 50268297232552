import React from 'react';
import { Button } from '@mui/material'

import TVImage1 from '../images/drugsAreBadMmkay/tv/fg.jpg'
import TVImage2 from '../images/drugsAreBadMmkay/tv/sp.jpg'
import TVImage3 from '../images/drugsAreBadMmkay/tv/c.jpg'

import MovieImage1 from '../images/drugsAreBadMmkay/movies/pe.jpg'
import MovieImage2 from '../images/drugsAreBadMmkay/movies/hb.jpg'
import MovieImage3 from '../images/drugsAreBadMmkay/movies/jasb.jpg'

import DateImage1 from '../images/drugsAreBadMmkay/dates/bcs.jpg'
import DateImage2 from '../images/drugsAreBadMmkay/dates/pacnh.png'
import DateImage3 from '../images/drugsAreBadMmkay/dates/420.png'
import DateImage4 from '../images/drugsAreBadMmkay/dates/dab.jpg'
import DateImage5 from '../images/drugsAreBadMmkay/dates/cab.jpg'


import SpotifyIcon from '../images/spotifyIcon.png'

import './contentStyles.css'

function ContentComponent(props)
{

    return (
      <div id="contentContainer" >
        <div class="contentContent">
          <h2>Hey FatStacks, Wanna Get High?</h2>
          <h4></h4>
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/12RmnQXoqIc" title="Drugs Are Bad, Mmkay" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p><img src={SpotifyIcon} id="spotifyIcon"/><a target="_blank" href="https://open.spotify.com/playlist/6r9CYeQNFe2vyKWy9zsBoa?si=686320876d934a22">Drugs Are Bad, Mmkay Playlist</a><img src={SpotifyIcon} id="spotifyIcon"/></p>
          <iframe src="https://open.spotify.com/embed/playlist/6r9CYeQNFe2vyKWy9zsBoa?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
          <p>Here at FatStacks, we like to have FUN! Sometimes fun means SonsOfXtinarchy selling their finest product. Sometimes that's summoning Will Arnett's floating skull head in a haze of purple. And soooometimes, that's some good ol' CLEAN fun with the dopest, doped up villagers like Bud or Cephalobot. We like to embrace getting high on life with good company, good vices,  and good gamez, so that's EXACTLY what we're gonna do this month! Who needs drugs with a game plan like this?! Not us! We would never!</p>

          <div class="row">
            <div class="column">
              <h3>Highly fun dates this Month</h3>

              <div class="contentEntry">
                <div class="imageContainer"><img src={DateImage1} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://www.amc.com/shows/better-call-saul--1002228">Better Call Saul Season Premiere</a><br/>
                    Mon 4/18</strong><br />
                    9:00 PM
                  </div>
                </div>
              </div>

              <div class="contentEntry">
                <div><img src={DateImage2} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://animalcrossing.fandom.com/wiki/Pietro">ACNH Pietro's Birthday</a><br/>
                    Tues 4/19</strong><br />
                    All Day
                  </div>
                </div>
              </div>

              <div class="contentEntry">
                <div><img src={DateImage3} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://www.history.com/news/the-hazy-history-of-420">420 UUNNHH!</a><br/>
                    Wed 4/20</strong><br />
                    All Day
                  </div>
                </div>
              </div>

              <div class="contentEntry">
                <div><img src={DateImage4} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://www.youtube.com/watch?v=XPejk_-rMDI">DA_BoB's Birthday</a><br/>
                    Mon 4/25</strong><br />
                    All Day
                  </div>
                </div>
              </div>

              <div class="contentEntry">
                <div><img src={DateImage5} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://www.youtube.com/watch?v=XPejk_-rMDI">crazyahwesome's Birthday</a><br/>
                    Fri 4/29</strong><br />
                    All Day
                  </div>
                </div>
              </div>

          </div>
          </div>
          <div class="row">
            <div class="column">
              <h3>Television</h3>

              <div class="contentEntry">
                <div class="imageContainer"><img src={TVImage1} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://www.hulu.com/watch/914b9c2d-951c-499f-9b40-945174cd7b1a">Family Guy</a><br/>
                    420, S7 EP 12</strong><br />
                    Hulu
                  </div>
                </div>
              </div>

              <div class="contentEntry">
                <div><img src={TVImage2} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://play.hbomax.com/episode/urn:hbo:episode:GXtU_GAFO6MPCwgEAAAAz">South Park</a><br/>
                    Tegridy Farms, S22 EP4</strong><br />
                    HBO Max
                  </div>
                </div>
              </div>

              <div class="contentEntry">
                <div><img src={TVImage3} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://www.discoveryplus.com/show/chopped-420">Chopped 420</a><br/>
                     Special</strong><br />
                    Discovery +
                  </div>
                </div>
              </div>

            </div>
            </div>
            <div class="row">
            <div class="column">
              <h3>Movies</h3>

              <div class="contentEntry">
                <div><img src={MovieImage1} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://play.hbomax.com/feature/urn:hbo:feature:GYbJ4GwCRvGPDwgEAAACx">Pineapple Express</a><br/>
                    HBO Max</strong>
                  </div>
                </div>
              </div>

              <div class="contentEntry">
                <div><img src={MovieImage2} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://www.amazon.com/gp/video/detail/B00D5UHIO8/ref=atv_hm_hom_3_c_D4dtpS_2_4">Half Baked</a><br/>
                    Amazon Prime</strong>
                  </div>
                </div>
              </div>

              <div class="contentEntry">
                <div><img src={MovieImage3} class="contentImg"/></div>
                <div class="contentText">
                  <div>
                    <strong><a target="_blank" href="https://www.amazon.com/Jay-Silent-Reboot-Jason-Mewes/dp/B081RVKQ45/ref=sr_1_1?crid=21W43QEMD5TJY&keywords=jay+and+silent+bob&qid=1648769032&s=instant-video&sprefix=jay+and+sil%2Cinstant-video%2C121&sr=1-1">Jay and Silent Bob Reboot</a><br/>
                    Amazon Prime</strong>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
}
export default ContentComponent;
